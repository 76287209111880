import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Typography, Button, Upload, Modal, Form } from 'antd'
import { styles, color } from '../helpers/styles'
import { postJson } from '../helpers/api'

const { Text } = Typography

const UploadMission = ({ postBack }) => {
  const [loading, setLoading] = useState(false)
  const [fileList, setFileList] = useState([])
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  let navigate = useNavigate()

  const handleCancel = () => setPreviewOpen(false)
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview)
    setPreviewOpen(true)
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    )
  }

  const handleChange = async ({ fileList: newFileList }) => {
    setFileList(newFileList)
  }

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  const mapValues = async (file, type) => {
    const base64 = await getBase64(file.originFileObj)
    const value = {
      type: type,
      key: file.name,
      value: base64,
    }
    return value
  }

  const onFinish = async (values) => {
    setLoading(true)
    const postValues = []
    if (values?.fileImage) {
      const file = values.fileImage.fileList
      for (let i = 0; i < file.length; i += 1) {
        const value = await mapValues(file[i], 'image')
        postValues.push(value)
      }
    }
    await postJson(`${postBack}`, postValues)
    navigate('/success')
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: window.innerHeight,
        }}
      >
        <div style={{ ...styles.main, flex: 1 }}>
          <div>
            <Text style={styles.text1}>
              คำถามอยู่ภายในคลิปภารกิจ (จดหมายถึงตัวเรา)
              อย่าลืม..เขียนชื่อนามสกุลในกระดาษคำตอบด้วยนะจ๊ะ
            </Text>
          </div>
          <br />
          <div style={{ textAlign: 'center' }}>
            <Form.Item name={'fileImage'} style={{ paddingTop: '5px' }}>
              <Upload
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                accept=".png,.jpeg"
                listType="picture-card"
                fileList={fileList}
                multiple
                onPreview={handlePreview}
                onChange={handleChange}
                beforeUpload={async (file) => {
                  return false
                }}
              >
                <Text style={{ ...styles.text1, color: color.primary }}>
                  อัปโหลดรูป
                </Text>
              </Upload>
            </Form.Item>
            <Modal
              open={previewOpen}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
          </div>
        </div>
        <div
          style={{
            textAlign: 'center',
            borderTop: '2px solid #DEE8F8',
          }}
        >
          <Form.Item style={{ padding: '8px 16px 0px 16px', marginBottom: 10 }}>
            <Button
              style={{
                height: '56px',
                backgroundColor:
                  fileList.length > 0 ? color.primary : color.grey,
              }}
              block
              type="primary"
              htmlType="submit"
              disabled={fileList.length > 0 ? false : true}
              loading={loading}
            >
              <Text style={{ ...styles.text5, color: color.white }}>
                ส่งการบ้าน
              </Text>
            </Button>
          </Form.Item>
        </div>
      </div>
    </Form>
  )
}
export default UploadMission
