import React, { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import WebFont from 'webfontloader'
import './App.css'
import QuizMission1 from './page/QuizMission1'
import Success from './page/Success'

function App() {
  return (
    <Router>
      <SomeOtherComponent />
    </Router>
  )
}

const SomeOtherComponent = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const postBack = queryParams.get('postback')

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Kanit'],
      },
    })
  }, [])

  return (
    <div>
      <Routes>
        <Route path="/" element={<QuizMission1 postBack={postBack} />} />
        <Route path="/success" element={<Success />} />
      </Routes>
    </div>
  )
}
export default App
