import { useState } from 'react'
import { Typography } from 'antd'
import { styles } from '../helpers/styles'
import Quiz from '../components/Quiz'
import UploadMission from '../components/UploadMission'

const { Text } = Typography

const QuizMission1 = ({ postBack }) => {
  const [page, setPage] = useState('quiz1')

  const title = [
    '1. สิ่งที่ต้องทำ เพื่อให้ได้รับเงินวงเงินสินเชื่อภารกิจพิชิตหมื่น คือข้อใด',
    '2. ข้อใดไม่ใช่การกระทำ เพื่อให้ได้คะแนนโนบูโร 70 คะแนน',
    '3. สินเชื่อภารกิจพิชิตหมื่น ได้รับวงเงินสูงสุดเท่าไหร่ และใช้เวลาเรียนรู้ทั้งหมดกี่สัปดาห์',
  ]
  const answer = [
    <div class="answer1">
      <Text style={styles.text1}>
        เงื่อนไขในการได้วงเงินสินเชื่อภารกิจพิชิตหมื่น ต้องทำ 3 ข้อนี้
      </Text>
      <br />
      <div style={{ paddingLeft: 10 }}>
        <Text style={styles.text1}>1. ทำภารกิจพิชิตหมื่นให้ครบ 4 ด่าน</Text>
        <br />
        <Text style={styles.text1}>2. ทำคะแนนโนบูโรให้ครบ 70 คะแนน</Text>
        <br />
        <Text style={styles.text1}>3. กดสมัครสินเชื่อภารกิจพิชิตหมื่น</Text>
      </div>
      <Text style={styles.text1}>
        หากไม่ทำครบ 3 ขั้นตอนนี้ จะไม่สามารถได้รับวงเงินสินเชื่อ
      </Text>
    </div>,
    <div class="answer2">
      <Text style={styles.text1}>
        หากต้องการให้คะแนนโนบูโรถึง 70 คะแนน ต้องทำ 3 สิ่งนี้ อย่างสม่ำเสมอ
      </Text>
      <br />
      <div style={{ paddingLeft: 10 }}>
        <Text style={styles.text1}>1. การจ่ายหนี้ตามแผน</Text>
        <br />
        <Text style={styles.text1}>2. การจดรับ-จ่าย</Text>
        <br />
        <Text style={styles.text1}>
          3. ข้อมูลที่จดรับ-จ่าย จ่ายหนี้ตามแผนทั้งหมด ต้องเป็นความจริงเท่านั้น
        </Text>
      </div>
    </div>,
    <div class="answer3">
      <Text style={styles.text1}>
        สินเชื่อภารกิจพิชิตหมื่น มีวงเงินสูงสุด 50,000 บาท โดยใช้เวลาทั้งหมด 16
        สัปดาห์ โดยจะได้รับวงเงินสินเชื่อทุกๆ 10,000 บาท เมื่อทำภารกิจพิชิตหมื่น
        ครบ 4 ด่าน และคะแนนโนบูโร ถึง 70
      </Text>
    </div>,
  ]
  const questions1 = [
    'ทำภารกิจพิชิตหมื่นครบ 4 ด่าน',
    'ทำคะแนนโนบูโรให้ถึง 70 คะแนน',
    'กดสมัครสินเชื่อภารกิจพิชิตหมื่น',
    'ถูกทุกข้อ',
  ]
  const questions2 = [
    'จดรับ-จ่าย ในแอปโนบูโร',
    'จ่ายหนี้ตามแผนในแอปโนบูโร',
    'พูดคุยในไลน์กับโนบูโร',
    'กรอกข้อมูลรับ - จ่าย การจ่ายหนี้ตามความจริง',
  ]
  const questions3 = [
    '10,000 บาท ภายใน 4 สัปดาห์',
    '20,000 บาท ภายใน 8 สัปดาห์',
    '50,000 บาท ภายใน 16 สัปดาห์',
    '100,000 บาท ภายใน 20 สัปดาห์',
  ]

  const mapping = {
    quiz1: (
      <Quiz
        title={title[0]}
        questions={questions1}
        answer={answer[0]}
        correct={4}
        setPage={setPage}
        nextPage={'quiz2'}
      />
    ),
    quiz2: (
      <Quiz
        title={title[1]}
        questions={questions2}
        answer={answer[1]}
        correct={3}
        setPage={setPage}
        nextPage={'quiz3'}
      />
    ),
    quiz3: (
      <Quiz
        title={title[2]}
        questions={questions3}
        answer={answer[2]}
        correct={3}
        setPage={setPage}
        nextPage={'upload'}
      />
    ),
    upload: <UploadMission postBack={postBack} />,
  }

  return mapping[page]
}

export default QuizMission1
